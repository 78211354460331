import {ActionTree, Module} from "vuex";
import api from "@/plugins/api";
import {
    GetLimitProductCategoriesRequest, GetLimitProductsRequest,
    GetLimitsRequest,
    Limit,
    LimitPeriod, LimitProduct, LimitProductCategory,
    LimitUnit,
    SaveLimitsRequest
} from "@/store/modules/limit/models";

export default <Module<any, any>>{
    namespaced: true,
    state: {
        list: [] as Limit[],
        units: [] as LimitUnit[],
        periods: [] as LimitPeriod[],
        products: [] as LimitProduct[],
        categories: [] as LimitProductCategory[],
    },
    getters: {
        limits(state): Limit[] {
            return state.list;
        },
        limitUnits(state): LimitUnit[] {
            return state.units;
        },
        limitUnitsByIds(state) {
            return function (ids: string[]): LimitUnit[] {
                return state.units.filter((unit: LimitUnit): boolean => ids.includes(unit.id));
            }
        },
        limitPeriods(state): LimitPeriod[] {
            return state.periods;
        },
        limitProducts(state): LimitProduct[] {
            return state.products;
        },
        limitProductsByCategory(state) {
            return function (categoryId: string): LimitProduct[] {
                return state.products.filter((product: LimitProduct): boolean => product.categoryId === categoryId);
            }
        },
        limitProductCategories(state): LimitProductCategory[] {
            return state.categories;
        },
    },
    mutations: {
        setList(state, data) {
            state.list = data;
        },
        setUnits(state, data) {
            state.units = data;
        },
        setCurrencies(state, data) {
            state.currencies = data;
        },
        setPeriods(state, data) {
            state.periods = data;
        },
        setProducts(state, data) {
            state.products = data;
        },
        setProductCategories(state, data) {
            state.categories = data;
        },
    },
    actions: <ActionTree<any, any>>{
        async fetchList(ctx, request: GetLimitsRequest): Promise<Limit[]> {
            const response  = await api.get<Limit[]>('api/lk/v1/limits', { params: request });
            return response.data;
        },
        async getListWithoutSave(ctx, request: GetLimitsRequest): Promise<Limit[]> {
            return await ctx.dispatch('fetchList', request);
        },
        async getListWithSave(ctx, request: GetLimitsRequest): Promise<Limit[]> {
            const data = await ctx.dispatch('fetchList', request);
            ctx.commit('setList', data);
            return data;
        },
        async getUnits(ctx): Promise<LimitUnit[]> {
            const response  = await api.get<LimitUnit[]>('api/lk/v1/limit_units');
            ctx.commit('setUnits', response.data);

            return response.data;
        },
        async getPeriods(ctx): Promise<LimitPeriod[]> {
            const response  = await api.get<LimitPeriod[]>('api/lk/v1/limit_periods');
            ctx.commit('setPeriods', response.data);

            return response.data;
        },
        async getProducts(ctx, request: GetLimitProductsRequest): Promise<LimitProduct[]> {
            const response  = await api.get<LimitProduct[]>('api/lk/v1/limit_products', { params: request });
            ctx.commit('setProducts', response.data);

            return response.data;
        },
        async getProductCategories(ctx, request: GetLimitProductCategoriesRequest): Promise<LimitProductCategory[]> {
            const response  = await api.get<LimitProductCategory[]>('api/lk/v1/limit_product_categories', { params: request });
            ctx.commit('setProductCategories', response.data);

            return response.data;
        },
        clearProductCategories({commit}) {
            commit('setProductCategories', []);
        },
        async saveLimits(ctx, request: SaveLimitsRequest) {
            await api.post('api/lk/v1/limits', request);
        },
    }
};